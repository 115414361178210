/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

import './style.css';

const faqs = [
  { id: 'one', caption: 'Kann ich hey.kitchen kostenlos testen?', description: 'Ja, Sie können hey.kitchen und die dazugehörige App ganz unverbindlich 30 Tage kostenlos testen.' },
  { id: 'two', caption: 'Wird hey.kitchen automatisch kostenpflichtig?', description: 'Nein, Es entsteht kein Abo nach der Testphase. Wenn Sie hey.kitchen weiter verwenden möchten, benötigen Sie ein Abo. Ihre Daten gehen dabei aber nicht verloren.' },
  { id: 'three', caption: 'Kann ich die kostenlose App im Store auch ohne ein Abo benutzen?', description: 'Sie können die App zwar kostenlos runterladen, aber die benötigten Aufträge für die Monteure müssen im Backoffice erstellt werden.' },
  { id: 'four', caption: 'Für welche Geräte kann ich die hey.kitchen Montage-App verwenden?', description: 'Es werden alle Geräte in allen Größen unterstützt, die entweder das Betriebsystem iOS (Apple) oder Android (Samsung, Huawei, LG, …) verwenden.' },
  { id: 'five', caption: 'Kann ich meinen Vertrag monatlich kündigen?', description: 'Ja, Sie haben die Möglichkeit Ihren Vertrag jederzeit zum Monatsende zu kündigen.' },
  { id: 'six', caption: 'Wie erfolgt die Abrechnung?', description: 'Sie können ganz bequem auf Rechnung bestellen oder ein Lastschriftmandat erstellen. ' },
];

function Row(props) {
  const { item } = props;

  return (
    <div className="card">
      <div className="card-header" id="headingOne">
        <h2 className="mb-0">
          <button
            className="btn btn-link"
            type="button"
            data-toggle="collapse"
            data-target={`#${item.id}`}
            aria-expanded={false}
            aria-controls={item.id}
            style={{ textAlign: 'left' }}
          >
            {item.caption}
          </button>
        </h2>
      </div>

      <div id={item.id} className="collapse collapsed" aria-labelledby="headingOne" data-parent="#accordionExample">
        <div className="card-body">
          {item.description}
        </div>
      </div>
    </div>
  );
}

Row.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
};

/**
 * FaqBox()
 * @returns {*}
 * @constructor
 */
export default function FaqBox() {
  return (
    <div className="FaqBox accordion" id="accordionExample">
      {faqs.map((item) => <Row key={item.id} item={item} />)}
    </div>
  );
}
