import React from 'react';

import './style.css';

/**
 * PricingBoxes()
 * @returns {*}
 * @constructor
 */
export default function PricingBoxes() {
  return (
    <div className="PricingBoxes">
      <div className="card">
        <div className="card-body">
          <h5 className="card-title Red">PREMIUM</h5>
          <div className="Pricing">
            <h6>€10 / pro Auftrag</h6>
            <p>Kleine & Mittelständige Unternehmen</p>
          </div>

          <table className="table">
            <tbody>
              <tr style={{ textAlign: 'center' }}>
                <td>cloud-basiert</td>
              </tr>
              <tr style={{ textAlign: 'center' }}>
                <td>hey.kitchen App für iOS und Android</td>
              </tr>
              <tr style={{ textAlign: 'center' }}>
                <td>Automatische Zeiterfassung</td>
              </tr>
              <tr style={{ textAlign: 'center' }}>
                <td>Berechnung der Montagezeit</td>
              </tr>
              <tr style={{ textAlign: 'center' }}>
                <td>Automatische Angebotskalkulation</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <h5 className="card-title Black">ENTERPRISE</h5>
          <div className="Pricing">
            <h6>€10 / pro Auftrag</h6>
            <p>Für Großkunden</p>
          </div>

          <table className="table">
            <tbody>
              <tr style={{ textAlign: 'center' }}>
                <td>cloud-basiert</td>
              </tr>
              <tr style={{ textAlign: 'center' }}>
                <td>hey.kitchen App für iOS und Android</td>
              </tr>
              <tr style={{ textAlign: 'center' }}>
                <td>Automatische Zeiterfassung</td>
              </tr>
              <tr style={{ textAlign: 'center' }}>
                <td>Berechnung der Montagezeit</td>
              </tr>
              <tr style={{ textAlign: 'center' }}>
                <td>Automatische Angebotskalkulation</td>
              </tr>
              <tr style={{ textAlign: 'center' }}>
                <td>Umfangreiche Statistiken</td>
              </tr>
              <tr style={{ textAlign: 'center' }}>
                <td>Anbindung Ihrer eigenen Domain</td>
              </tr>
              <tr style={{ textAlign: 'center' }}>
                <td>Individual-Entwicklung</td>
              </tr>
              <tr style={{ textAlign: 'center' }}>
                <td>Premium Direktbetreuung</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
