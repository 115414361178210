import React from 'react';
import { useSelector } from 'react-redux';
import Panel from '../../../Components/Panel';
import MaterialTable from '../../../Components/Agb/MaterialTable';
import { CLIENTTYPE_DEALER } from '../../../Library/Types';
/**
 * Material()
 * @returns {*}
 * @constructor
 */
export default function Material() {
  const { type } = useSelector((state) => state.auth.client);

  const renderInfoText = () => {
    if (type === CLIENTTYPE_DEALER) {
      return 'Für zusätzliche Materialien, die wir für die Erbringung unserer Dienstleistung der Küchenmontage,'
        + ' insbesondere für den Elektro- und/oder Sanitäranschluss, benötigen, gilt unsere jeweils gültige'
        + ' folgende Preisliste. Die Preise sind nur relevant, wenn wir, die hey.kitchen GmbH für Sie'
        + ' bundesweit Küchenmontagen durchführen.';
    }
    return 'Für zusätzliche Materialien, die Sie als Partner für die Erbringung Ihrer Leistungen der Küchenmontage,'
      + ' insbesondere für den Elektro- und/oder Sanitäranschluss, benötigen, werden die diese lt. folgender'
      + ' Preisliste gutgeschrieben. Die Preise sind nur relevant, wenn Sie für die hey.kitchen GmbH bundesweit'
      + ' Küchenmontagen durchführen.';
  };
  return (
    <div className="row">
      <div className="col-lg-12">
        <Panel marginBottom={20}>
          <div className="d-flex flex-row">
            <div className="flex-grow-1">
              <h4>Übersicht der Materialkosten</h4>
              <p style={{ marginBottom: 0 }}>
                {renderInfoText()}
              </p>
            </div>
          </div>
        </Panel>
        <Panel marginBottom={20}>
          <MaterialTable
            type={type}
            isWithDescription
          />
        </Panel>
      </div>
    </div>
  );
}

Material.propTypes = {
};
