import React from 'react';
import InfoBox from '../InfoBox';

import './style.css';

const softwareArrow = require('../../../../Assets/Images/pfeil_gelb.png');
const screenshot01 = require('../../../../Assets/Images/Backend/hey-kitchen-backend-orders.png');

/**
 * OurHeyKitchen()
 * @returns {*}
 * @constructor
 */
export default function OurHeyKitchen() {
  return (
    <div
      className="OurHeyKitchen FullPageBox Light Center"
      style={{ backgroundImage: `url(${softwareArrow})` }}
    >
      <div className="container d-flex flex-column">
        <h2>unsere hey.kitchen Software</h2>
        <p>
          Sie sind Experte beim Verkauf, der Lieferung und Montage von Küchen? Hervorragend! Genau für Sie
          haben wir hey.kitchen entwickelt. Die Software erleichtert Ihnen nicht nur die Montage sondern spart
          Ihnen auch viel Geld und Zeit. Unsere Webanwendung in Kombination mit der Montage App bildet den gesamten
          Küchenprozess digital ab.
        </p>
        <img src={screenshot01} alt="HK Screenshot" style={{ width: '100%', marginTop: 60 }} />

        <div className="Listing" style={{ marginTop: 50 }}>
          <InfoBox headline="Warum hey.kitchen" icon="fas fa-truck" withBox iconColor="#e7184e">
            hey.kitchen vereint jahrelange Erfahrung für Küchenmontage mit Informationstechnologie. Wir kennen
            Ihr Tagesgeschäft und digitalisieren Ihre Prozesse. So haben Sie mehr Zeit und machen mehr Gewinn!
          </InfoBox>
          <InfoBox headline="Mehr Effektivität" icon="fas fa-tasks" withBox iconColor="#e7184e">
            Schnellere Disposition und einfachere Montageabwicklungen durch intelligente Algorithmen.
            Ihre Mitarbeiter werden unterstützt, behalten aber selbstverständlich die Kontrolle.
          </InfoBox>
          <InfoBox headline="Mehr Ordnung" icon="fas fa-sort-amount-up" withBox iconColor="#e7184e">
            Ihre Mitarbeiter im Büro und beim Kunden arbeiten gemeinsam mit dem gleichen System. Vom Auftrag
            bis zur Montage alles digitalisiert. Kein Papierkram und keine Notizzettel mehr.
          </InfoBox>
        </div>
      </div>
    </div>
  );
}
