import React, { useEffect } from 'react';
import WebdemoRow from '../Components/WebdemoRow';
import AppStoreBanner from '../Components/AppStoreBanner';
import FaqBox from '../Components/FaqBox';
import Button from '../../../Components/Button';
import WebdemoContact from '../Components/WebdemoContact';
import Footer from '../Components/Footer';
import StartpageHeader from '../Components/StartpageHeader';
import OurHeyKitchen from '../Components/OurHeyKitchen';
import ApplicationRow from '../Components/ApplicationRow';
import PricingRow from '../Components/PricingRow';
import ProcessDescription from '../Components/ProcessDescription';

/**
 * Startpage()
 * @returns {*}
 * @constructor
 */
export default function Startpage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="d-flex flex-grow-1 flex-column" style={{ margin: -40 }}>
      <StartpageHeader />

      <WebdemoRow />

      <ProcessDescription />

      <div className="FullPageBox Gray" style={{ padding: '30px 0' }} />

      <OurHeyKitchen />
      <WebdemoRow />
      <ApplicationRow />
      <AppStoreBanner />
      <PricingRow />
      <WebdemoRow />

      <div className="FullPageBox Light Center">
        <div className="container d-flex flex-column">
          <h2>FAQ</h2>
          <p style={{ marginBottom: 60 }}>
            Wir haben die wichtigsten Fragen für Sie schon beantwortet.
          </p>

          <FaqBox />

          <h2 style={{ marginTop: 60 }}>Ihr Frage wurde nicht beantwortet?</h2>
          <p style={{ marginBottom: 60 }}>
            Schreiben Sie uns eine E-Mail. Gerne beantworten wir Ihre Fragen.
          </p>

          <a href="mailto:info@hey.kitchen">
            <Button onClick={() => {}}>Kontakt</Button>
          </a>
        </div>
      </div>

      <WebdemoContact />
      <Footer />
    </div>
  );
}
