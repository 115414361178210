import React from 'react';
import PropTypes from 'prop-types';
import ModalDialog from '../ModalDialog';

import './style.css';

/**
 * Container()
 * @param props
 * @returns {*}
 * @constructor
 */
export default function Container(props) {
  const {
    visible, onClose, isPopup, children,
  } = props;

  if (isPopup) {
    return (
      <ModalDialog onClose={onClose} hideConfirmButton closeCaption="OK" visible={visible} width={1200}>
        <div className="AgbDialog ScrollContainer">
          <div className="InnerContainer">
            {children}
          </div>
        </div>
      </ModalDialog>
    );
  }
  return (
    <>
      {children}
    </>
  );
}

Container.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  isPopup: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

Container.defaultProps = {
  onClose: () => {},
};
