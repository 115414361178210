/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Redirect, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { isMobileOnly } from 'react-device-detect';
import Button from '../Components/Button';
import Input from '../Components/Input';
import Panel from '../Components/Panel';
import { login } from '../Redux/Action/AuthAction';
import { register } from '../Redux/Action/ClientAction';
import { legalFormOptions, clientType } from '../Library/Types';
import SelectPanel from '../Components/SelectPanels';
import LoadingModal from '../Components/LoadingModal';
import Checkbox from '../Components/Checkbox';
import Footer from './Frontend/Components/Footer';
import getErrorMessage from '../Library/ErrorCodeMappings';
import MobileDeactivatedHint from '../Components/MobileDeactivatedHint';
import AgbDialogDealer from '../Components/Agb/AgbDialogDealer';
import AgbDialogMonteur from '../Components/Agb/AgbDialogMonteur';
import CustomReactSelect from '../Components/CustomReactSelect';
import SaasAgb from '../Assets/AGB_SaaS.pdf';
import DealerAgb from '../Assets/AGB_Dealer.pdf';
import MonteurAgb from '../Assets/AGB_Monteur.pdf';
import Config from '../Assets/Config';

const panelDescription = 'Bitte geben Sie Ihre Daten ein. Nach erfolgreicher Registrierung und dem ersten Login '
  + 'müssen Sie Ihre Profildaten vervollständigen!';

function Register(props) {
  // eslint-disable-next-line no-shadow
  const { isLoggedin } = props;
  const [name, setName] = useState();
  const [type, setType] = useState('DEALER');
  const [legalForm, setLegalForm] = useState();
  const [firstname, setFirstname] = useState();
  const [lastname, setLastname] = useState();
  const [mail, setMail] = useState();
  const [phone, setPhone] = useState();
  const [password, setPassword] = useState();
  const [formError, setFormError] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [showAgbDealer, setShowAbgDealer] = useState(false);
  const [showAgbMonteur, setShowAbgMonteur] = useState(false);
  const [agb, setAgb] = useState(false);
  const [saasAgb, setSaasAgb] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    if (Config.env === 'production') {
      window.location.replace('https://v2.hey.kitchen/public/register');
    }
  }, []);

  if (Config.env === 'production') {
    return null;
  }

  const handleOnRegister = () => {
    setFormError([]);
    const formData = {
      type, name, firstname, lastname, mail, phone, password, legalForm,
    };

    if (agb && saasAgb) {
      setIsLoading(true);
      props.register(formData).then(() => {
        props.login(formData.mail, formData.password).then(() => {
          setIsLoading(false);
          props.history.push('/dashboard');
        });
      }).catch((error) => {
        if (error.data) {
          const { errorCode, errorMessage } = error.data;
          if (errorCode === 'FORMDATA_INVALID') {
            setFormError(errorMessage);
          } else if (errorCode === 'CLIENT_MAIL_ALREADY_EXISTING' || errorCode === 'USER_MAIL_ALREADY_EXISTING') {
            setFormError({ mail: 'CLIENT_MAIL_ALREADY_EXISTING' });
          } else if (errorCode === 'CLIENT_NAME_ALREADY_EXISTING') {
            setFormError({ name: 'CLIENT_NAME_ALREADY_EXISTING' });
          }
        }
        setIsLoading(false);
      });
    } else {
      setFormError({ agb: !agb, saasAgb: !saasAgb });
    }
  };

  if (isMobileOnly) {
    return <MobileDeactivatedHint />;
  }

  if (!isLoggedin) {
    return (
      <div className="d-flex flex-column flex-grow-1" style={{ margin: '-40px' }}>
        <div
          className="d-flex flex-grow-1 justify-content-center align-items-center align-content-center"
          style={{ backgroundColor: '#F1F1F2', padding: '50px 0' }}
        >
          <Panel
            width="700px"
            shadow
          >
            <h3 className="hk-text-primary">
              Kostenlose Registrierung
            </h3>
            <p>
              {panelDescription}
            </p>

            <hr style={{ margin: '40px 0' }} />

            <SelectPanel
              onChange={(value) => setType(value)}
              data={clientType}
              selected={[type]}
              hasError={!!(formError.type)}
            />

            <br />

            <Input
              label="Firmenname (Rechnungsadresse)"
              onChange={(value) => setName(value)}
              value={name}
              hasError={!!(formError.name)}
              errorMessage={getErrorMessage(formError.name)}
              inputClassName="form-control-lg"
            />

            <div className="row">
              <CustomReactSelect
                style={{ marginBottom: 20 }}
                onChange={(value) => setLegalForm(value)}
                value={legalForm}
                options={legalFormOptions}
                label="Rechtsform"
                hasError={!!(formError.legalForm)}
                inputClassName="custom-select-lg"
                rowClass="col-lg-12"
                isLarge
              />

              <Input
                label="Vorname"
                onChange={(value) => setFirstname(value)}
                value={firstname}
                rowClass="col-lg-6"
                hasError={!!(formError.firstname)}
                inputClassName="form-control-lg"
              />
              <Input
                label="Nachname"
                onChange={(value) => setLastname(value)}
                value={lastname}
                rowClass="col-lg-6"
                hasError={!!(formError.lastname)}
                inputClassName="form-control-lg"
              />
            </div>
            <div className="row">
              <Input
                label="Telefon"
                onChange={(value) => setPhone(value)}
                value={phone}
                rowClass="col-lg-6"
                hasError={!!(formError.phone)}
                inputClassName="form-control-lg"
              />
              <Input
                label="E-Mail Adresse"
                onChange={(value) => setMail(value)}
                value={mail}
                rowClass="col-lg-6"
                hasError={!!(formError.mail)}
                errorMessage={getErrorMessage(formError.mail)}
                inputClassName="form-control-lg"
              />
            </div>
            <Input
              label="Passwort"
              rightIcon="fas fa-key"
              onChange={(value) => setPassword(value)}
              value={password}
              type="password"
              hasError={!!(formError.password)}
              inputClassName="form-control-lg"
            />
            <Checkbox onChange={(data) => setAgb(data)} value={agb} hasError={!!(formError.agb)}>
              {'Ich habe die hey.kitchen '}
              <a href={(type === 'DEALER') ? DealerAgb : MonteurAgb} target="_blank" rel="noreferrer noopener">
                AGB
              </a>
              {' gelesen und stimme zu'}
            </Checkbox>
            <Checkbox onChange={(data) => setSaasAgb(data)} value={saasAgb} hasError={!!(formError.saasAgb)}>
              {'Ich habe die hey.kitchen Software '}
              <a href={SaasAgb} target="_blank" rel="noreferrer noopener">AGB</a>
              {' gelesen und stimme zu'}
            </Checkbox>

            <hr style={{ margin: '30px 0' }} />

            <div className="row">
              <div className="d-flex col-lg-6">
                <div className="d-flex align-self-center">
                  <small>
                    <NavLink to="/login">Zur Anmeldung</NavLink>
                  </small>
                </div>
              </div>
              <div className="col-lg-6">
                <Button className="float-right" onClick={() => handleOnRegister()}>
                  Jetzt kostenlos registrieren
                </Button>
              </div>
            </div>

          </Panel>

          <AgbDialogDealer visible={showAgbDealer} onClose={() => setShowAbgDealer(false)} />
          <AgbDialogMonteur visible={showAgbMonteur} onClose={() => setShowAbgMonteur(false)} />
          <LoadingModal visible={isLoading} text="Registrierung wird gesendet..." />
        </div>
        <div>
          <Footer type="Dark" />
        </div>
      </div>
    );
  }

  return <Redirect to="/dashboard" />;
}

function mapStoreToProps(store) {
  return {
    isLoggedin: store.auth.isLoggedin,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    register: (formData) => dispatch(register(formData)),
    login: (mail, password) => dispatch(login(mail, password)),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(Register));
