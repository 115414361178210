import React from 'react';
import { useParams } from 'react-router';
import AgbDialogDealer from '../Components/Agb/AgbDialogDealer';
import AgbDialogMonteur from '../Components/Agb/AgbDialogMonteur';

/**
 * Agb()
 * @returns {null|*}
 * @constructor
 */
export default function Agb() {
  const { type } = useParams();

  const renderAgb = () => {
    if (type.toLowerCase() === 'd') {
      return <AgbDialogDealer isPopup={false} visible />;
    }
    return <AgbDialogMonteur isPopup={false} visible />;
  };

  return (
    <div className="row">
      <div className="col-lg-12">
        {renderAgb()}
      </div>
    </div>
  );
}
