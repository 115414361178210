import React, { useReducer } from 'react';
import PropTypes from 'prop-types/prop-types';
import { useParams, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { isMobileOnly } from 'react-device-detect';
import Button from '../Components/Button';
import Input from '../Components/Input';
import Panel from '../Components/Panel';
import { register } from '../Redux/Action/ClientAction';
import { legalFormOptions, clientType } from '../Library/Types';
import SelectPanel from '../Components/SelectPanels';
import LoadingModal from '../Components/LoadingModal';
import Checkbox from '../Components/Checkbox';
import MobileDeactivatedHint from '../Components/MobileDeactivatedHint';
import AgbDialogDealer from '../Components/Agb/AgbDialogDealer';
import AgbDialogMonteur from '../Components/Agb/AgbDialogMonteur';
import SaasAgb from '../Assets/AGB_SaaS.pdf';
import DealerAgb from '../Assets/AGB_Dealer.pdf';
import MonteurAgb from '../Assets/AGB_Monteur.pdf';
import KeyValueEditRow from '../Components/KeyValueEditRow';
import { login } from '../Redux/Action/AuthAction';
import { auth, getMail } from '../Redux/Action/ClientInviteAction';

function reducer(state, action) {
  switch (action.type) {
    case 'firstname':
      return { ...state, firstname: action.payload };
    case 'lastname':
      return { ...state, lastname: action.payload };
    case 'name':
      return { ...state, name: action.payload };
    case 'legalForm':
      return { ...state, legalForm: action.payload };
    case 'mail':
      return { ...state, mail: action.payload };
    case 'web':
      return { ...state, web: action.payload };
    case 'zip':
      return {
        ...state,
        zip: (action.payload) ? action.payload.trim() : '',
      };
    case 'city':
      return { ...state, city: action.payload };
    case 'street':
      return { ...state, street: action.payload };
    case 'streetNo':
      return { ...state, streetNo: action.payload };
    case 'phone':
      return { ...state, phone: action.payload };
    case 'fax':
      return { ...state, fax: action.payload };
    case 'vat':
      return { ...state, vat: action.payload };
    case 'type':
      return { ...state, type: action.payload };
    case 'devoutIdentificationNumber':
      return { ...state, devoutIdentificationNumber: action.payload };
    case 'init':
      return { ...action.payload, changed: false };

    default:
      throw new Error();
  }
}

function Invite(props) {
  const {
    dispatchAuthenticate, dispatchRegister, dispatchLogin, history, dispatchGetMailFromHash,
  } = props;

  const { hash } = useParams();
  const [mail, setMail] = React.useState();
  const [password, setPassword] = React.useState();
  const [repeatedPassword, setRepeatedPassword] = React.useState();
  const [formError, setFormError] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const [showAgbDealer, setShowAbgDealer] = React.useState(false);
  const [showAgbMonteur, setShowAbgMonteur] = React.useState(false);
  const [agb, setAgb] = React.useState(false);
  const [saasAgb, setSaasAgb] = React.useState(false);
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const [state, dispatch] = useReducer(reducer, '');
  const [currentTabIndex, setCurrentTabIndex] = React.useState(null);
  const [legalFormString, setLegalFormString] = React.useState(null);

  React.useEffect(() => {
    dispatchGetMailFromHash(hash).then((response) => {
      setMail(response[0]);
    });
  }, [hash, dispatchGetMailFromHash]);

  React.useEffect(() => {
    if (state.legalForm) {
      const legal = legalFormOptions.filter((item) => item.value === parseInt(state.legalForm, 0))[0];
      setLegalFormString(legal.label);
    }
  }, [state.legalForm]);

  const handleOnRegister = () => {
    setFormError([]);
    if (password !== repeatedPassword) {
      setFormError({ password: 'PASSWORDS_NOT_MATCHING' });
      return;
    }
    const formData = state;
    formData.password = password;
    if (formData.phone.length <= 1) {
      delete formData.phone;
    }
    if (agb && saasAgb) {
      setIsLoading(true);
      dispatchRegister(formData).then(() => {
        dispatchLogin(formData.mail, formData.password).then(() => {
          setIsLoading(false);
          history.push('/dashboard');
        });
      }).catch((error) => {
        if (error.data) {
          const { errorCode, errorMessage } = error.data;
          if (errorCode === 'FORMDATA_INVALID') {
            setFormError(errorMessage);
          } else if (errorCode === 'CLIENT_MAIL_ALREADY_EXISTING' || errorCode === 'USER_MAIL_ALREADY_EXISTING') {
            setFormError({ mail: 'CLIENT_MAIL_ALREADY_EXISTING' });
          } else if (errorCode === 'CLIENT_NAME_ALREADY_EXISTING') {
            setFormError({ name: 'CLIENT_NAME_ALREADY_EXISTING' });
          }
        }
        setIsLoading(false);
      });
    } else {
      setFormError({ agb: !agb, saasAgb: !saasAgb });
    }
  };

  if (isMobileOnly) {
    return <MobileDeactivatedHint />;
  }

  const handleOnClick = () => {
    dispatchAuthenticate(mail, password).then((response) => {
      setIsLoggedIn(true);
      dispatch({ type: 'init', payload: response });
      setPassword('');
    });
  };

  if (!isLoggedIn) {
    return (
      <div
        className="d-flex flex-grow-1 justify-content-center align-items-center align-content-center"
        style={{ backgroundColor: '#F1F1F2', padding: 50, margin: -40 }}
      >
        <Panel width="700px">
          <h3 className="hk-text-primary">
            Zu Ihrer Einladung
          </h3>
          <p style={{ marginBottom: 30 }}>
            Bitte geben Sie die E-Mail Adresse und das Passwort ein, welches wir Ihnen per E-Mail zugesendet haben. Sie
            können dann die bereits vom Partner eingegeben Informationen sehen und bearbeiten.
          </p>

          <Input
            rightIcon="fas fa-user"
            onChange={(value) => setMail(value)}
            value={mail}
            placeholder="Benutzername / E-Mail"
            errorMessage="Benutzername oder E-Mail ungültig"
            inputClassName="form-control-lg"
          />
          <Input
            rightIcon="fas fa-key"
            type="password"
            onChange={(value) => setPassword(value)}
            value={password}
            placeholder="Passwort"
            errorMessage="Passwort ungültig"
            inputClassName="form-control-lg"
          />

          <br />

          <div className="row">
            <div className="col-lg-12">
              <Button className="float-right" onClick={handleOnClick}>
                Anmelden
              </Button>
            </div>
          </div>
        </Panel>
      </div>
    );
  }

  return (
    <div
      className="row d-flex flex-grow-1"
      style={{
        backgroundColor: '#F1F1F2', margin: -40, paddingTop: 40, paddingBottom: 40,
      }}
    >
      <div className="col-lg-3" />
      <div className="col-lg-6">
        <Panel marginBottom={25}>
          <h3 className="hk-text-primary">
            Anfrage bestätigen & Account erstellen
          </h3>
          <p>
            Ihr Partner hat bereits einige Stammdaten für Ihr Unternehmen bei der Einladung hinterlegt. Sie können
            fehlenden Informationen entweder jetzt oder später in den Firmeneinstellungen vervollständigen.
            Wenn Sie die Einladung annehmen und Ihren hey.kitchen Account registrieren, sind sie direkt mit dem Partner
            auf hey.kitchen verknüpft und können mit der Zusammenarbeit starten. Der Partner wird autom. informiert
            das Sie die Einladung angenommen und den Account registriert haben.
          </p>

          <div style={{ marginBottom: 20 }}>
            <SelectPanel
              onChange={(value) => dispatch({ type: 'type', payload: value })}
              data={clientType}
              selected={[state.type]}
              hasError={!!(formError.type)}
            />
          </div>

          <KeyValueEditRow
            title="Rechtsform"
            type="select"
            initialValue={state.legalForm}
            displayText={legalFormString}
            onChange={(value) => dispatch({ type: 'legalForm', payload: value })}
            selectData={legalFormOptions}
            containerTabIndex={1}
            hasError={!!(formError.legalForm)}
            required
          />
          <KeyValueEditRow
            title="Firmenname"
            type="text"
            initialValue={state.name}
            displayText={state.name}
            onChange={(value) => dispatch({ type: 'name', payload: value })}
            containerTabIndex={2}
            hasError={!!(formError.name)}
            required
          />

          <div className="row" style={{ marginTop: 25 }}>
            <div className="col-lg-9">
              <KeyValueEditRow
                title="Strasse"
                type="text"
                initialValue={state.street}
                displayText={state.street}
                onChange={(value) => dispatch({ type: 'street', payload: value })}
                containerTabIndex={3}
                startEditMode={(currentTabIndex === 3)}
                onPressEnterGetNextTabIndex={setCurrentTabIndex}
              />
            </div>
            <div className="col-lg-3">
              <KeyValueEditRow
                title="Hausnummer"
                type="text"
                initialValue={state.streetNo}
                displayText={state.streetNo}
                onChange={(value) => dispatch({ type: 'streetNo', payload: value })}
                containerTabIndex={3}
                startEditMode={(currentTabIndex === 4)}
                onPressEnterGetNextTabIndex={setCurrentTabIndex}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3">
              <KeyValueEditRow
                title="Postleitzahl"
                type="text"
                initialValue={state.zip}
                displayText={state.zip}
                onChange={(value) => dispatch({ type: 'zip', payload: value })}
                containerTabIndex={5}
                startEditMode={(currentTabIndex === 5)}
                onPressEnterGetNextTabIndex={setCurrentTabIndex}
              />
            </div>
            <div className="col-lg-9">
              <KeyValueEditRow
                title="Ort"
                type="text"
                initialValue={state.city}
                displayText={state.city}
                onChange={(value) => dispatch({ type: 'city', payload: value })}
                containerTabIndex={6}
                startEditMode={(currentTabIndex === 6)}
                onPressEnterGetNextTabIndex={setCurrentTabIndex}
              />
            </div>
          </div>

          <div className="row" style={{ marginTop: 25 }}>
            <div className="col-lg-6">
              <KeyValueEditRow
                title="Telefon"
                type="text"
                initialValue={state.phone}
                displayText={state.phone}
                onChange={(value) => dispatch({ type: 'phone', payload: value })}
                containerTabIndex={7}
                startEditMode={(currentTabIndex === 7)}
                onPressEnterGetNextTabIndex={setCurrentTabIndex}
                hasError={!!(formError.phone)}
                required
              />
            </div>
            <div className="col-lg-6">
              <KeyValueEditRow
                title="Fax"
                type="text"
                initialValue={state.fax}
                displayText={state.fax}
                onChange={(value) => dispatch({ type: 'fax', payload: value })}
                containerTabIndex={8}
                startEditMode={(currentTabIndex === 8)}
                onPressEnterGetNextTabIndex={setCurrentTabIndex}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6">
              <KeyValueEditRow
                title="E-Mail Adresse"
                type="text"
                initialValue={state.mail}
                displayText={state.mail}
                onChange={(value) => dispatch({ type: 'mail', payload: value })}
                containerTabIndex={9}
                startEditMode={(currentTabIndex === 9)}
                onPressEnterGetNextTabIndex={setCurrentTabIndex}
                hasError={!!(formError.mail)}
                required
              />
            </div>
            <div className="col-lg-6">
              <KeyValueEditRow
                title="Webseite"
                type="text"
                initialValue={state.web}
                displayText={state.web}
                onChange={(value) => dispatch({ type: 'web', payload: value })}
                containerTabIndex={10}
                startEditMode={(currentTabIndex === 10)}
                onPressEnterGetNextTabIndex={setCurrentTabIndex}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6">
              <KeyValueEditRow
                title="Umsatzsteuer-Identifikationsnummer"
                type="text"
                initialValue={state.vat}
                displayText={state.vat}
                onChange={(value) => dispatch({ type: 'vat', payload: value })}
                containerTabIndex={11}
                startEditMode={(currentTabIndex === 11)}
                onPressEnterGetNextTabIndex={setCurrentTabIndex}
              />
            </div>
            <div className="col-lg-6">
              <KeyValueEditRow
                title="Gläubiger-Identifikationsnummer"
                type="text"
                initialValue={state.devoutIdentificationNumber}
                displayText={state.devoutIdentificationNumber}
                onChange={(value) => dispatch({ type: 'devoutIdentificationNumber', payload: value })}
                containerTabIndex={12}
                startEditMode={(currentTabIndex === 12)}
                onPressEnterGetNextTabIndex={setCurrentTabIndex}
              />
            </div>
          </div>

          <div className="row" style={{ marginTop: 25 }}>
            <div className="col-lg-6">
              <KeyValueEditRow
                title="Ihr Vorname"
                type="text"
                initialValue={state.firstname}
                displayText={state.firstname}
                onChange={(value) => dispatch({ type: 'firstname', payload: value })}
                containerTabIndex={13}
                startEditMode={(currentTabIndex === 13)}
                onPressEnterGetNextTabIndex={setCurrentTabIndex}
                required
              />
            </div>
            <div className="col-lg-6">
              <KeyValueEditRow
                title="Ihr Nachname"
                type="text"
                initialValue={state.lastname}
                displayText={state.lastname}
                onChange={(value) => dispatch({ type: 'lastname', payload: value })}
                containerTabIndex={14}
                startEditMode={(currentTabIndex === 14)}
                onPressEnterGetNextTabIndex={setCurrentTabIndex}
                required
              />
            </div>
          </div>
        </Panel>

        <Panel marginBottom={25}>
          <h5 className="hk-text-primary">Account Informationen</h5>
          <p style={{ marginBottom: 10 }}>
            Bitte geben Sie ein neues Passwort an, mit dem Sie sich zukünftig bei hey.kitchen anmelden möchten.
          </p>
          <p style={{ marginBottom: 30 }}>
            <strong>Hinweis: </strong>
            Das Passwort muss aus min. 8 Zeichen bestehen.
          </p>
          <div className="row">
            <Input
              label="Passwort"
              rightIcon="fas fa-key"
              onChange={(value) => setPassword(value)}
              value={password}
              type="password"
              hasError={!!(formError.password)}
              required
              rowClass="col-lg-6"
            />
            <Input
              label="Passwort wiederholen"
              rightIcon="fas fa-key"
              onChange={(value) => setRepeatedPassword(value)}
              value={repeatedPassword}
              type="password"
              hasError={!!(formError.password)}
              required
              rowClass="col-lg-6"
            />
          </div>

          <Checkbox onChange={(data) => setAgb(data)} value={agb} hasError={!!(formError.agb)}>
            {'Ich habe die hey.kitchen '}
            <a href={(state.type === 'DEALER') ? DealerAgb : MonteurAgb} target="_blank" rel="noreferrer noopener">
              AGB
            </a>
            {' gelesen und stimme zu'}
          </Checkbox>

          <Checkbox onChange={(data) => setSaasAgb(data)} value={saasAgb} hasError={!!(formError.saasAgb)}>
            {'Ich habe die hey.kitchen Software '}
            <a href={SaasAgb} target="_blank" rel="noreferrer noopener">AGB</a>
            {' gelesen und stimme zu'}
          </Checkbox>
        </Panel>

        <Panel boxPadding={10}>
          <div className="row">
            <div className="col-lg-12">
              <Button className="float-right" onClick={handleOnRegister}>
                Einladung bestätigen & Account erstellen
              </Button>
            </div>
          </div>
        </Panel>

        <AgbDialogDealer visible={showAgbDealer} onClose={() => setShowAbgDealer(false)} />
        <AgbDialogMonteur visible={showAgbMonteur} onClose={() => setShowAbgMonteur(false)} />
        <LoadingModal visible={isLoading} text="Registrierung wird gesendet..." />
      </div>
      <div className="col-lg-3" />
    </div>
  );
}

Invite.propTypes = {
  dispatchAuthenticate: PropTypes.func.isRequired,
  dispatchRegister: PropTypes.func.isRequired,
  dispatchLogin: PropTypes.func.isRequired,
  dispatchGetMailFromHash: PropTypes.func.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
};

function mapStoreToProps(store) {
  return {
    isLoggedin: store.auth.isLoggedin,
    clientInvite: store.clientInvite.usersInvite,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchRegister: (formData) => dispatch(register(formData, true)),
    dispatchLogin: (mail, password) => dispatch(login(mail, password)),
    dispatchAuthenticate: (mail, password) => dispatch(auth(mail, password)),
    dispatchGetMailFromHash: (hash) => dispatch((getMail(hash))),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(Invite));
