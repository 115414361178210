import React from 'react';
import PropTypes from 'prop-types';
import ModalDialog from '../../../../Components/ModalDialog';
import DealerAgb from '../../../../Assets/AGB_Dealer.pdf';

export default function ApproveExternalOrderModal(props) {
  const { onClose, onConfirm } = props;

  const handleOnConfirm = () => {
    if (onConfirm) {
      onConfirm();
    }
    if (onClose) {
      onClose();
    }
  };

  return (
    <ModalDialog
      confirmCaption="Angebot jetzt verbindlich annehmen"
      onConfirm={handleOnConfirm}
      closeCaption="Zurück"
      onClose={onClose}
      visible
    >
      <strong>Annahme (Vertragsschluss)</strong>
      <br />
      Hiermit bestätigen Sie, dass Sie den Vertrag zu den von der hey.kitchen GmbH angebotenen
      Konditionen annehmen (§ 145 BGB).
      Die Leistung erfolgt zu unseren
      {' '}
      <a href={DealerAgb} target="_blank" rel="noreferrer noopener">
        allgemeinen Geschäftsbedingungen
      </a>
      .
      <br />
      Eine nachträgliche Vertragsänderung ist ausschließlich in Übereinstimmung mit der hey.kitchen GmbH möglich.
      Den Status Ihres Auftrag können Sie jederzeit unter Ihren Aufträgen einsehen. Sie werden jeweils per
      E-Mail über den aktuellen Status Ihres Auftrages informiert.
    </ModalDialog>
  );
}

ApproveExternalOrderModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

ApproveExternalOrderModal.defaultProps = {};
