import React from 'react';

import './style.css';

const processFullNormal = require('../../../../Assets/Images/process-saas-4.png');
const processFullMobile = require('../../../../Assets/Images/process-saas-2.png');

/**
 * ProcessDescription()
 * @returns {*}
 * @constructor
 */
export default function ProcessDescription() {
  return (
    <div className="ProcessDescription FullPageBox Light Center">
      <div className="container d-flex flex-column">
        <h2 style={{ marginBottom: 10 }}>Weniger Papierkram. Mehr Gewinn.</h2>
        <h2>Küchenmontage genial digital!</h2>
        <p>
          Stundenzettel verlegt? Montagebericht nicht unterschrieben? Reklamation nicht auffindbar? Überblick
          über Monteure und Aufträge verloren? hey.kitchen hilft zu mehr Ordnung, Effektivität und Gewinn.
        </p>
        <img
          src={processFullNormal}
          className="NormalImage"
          alt="HK Prozess Full"
        />
        <img
          src={processFullMobile}
          className="MobileImage"
          alt="HK Prozess Full"
        />
      </div>
    </div>
  );
}
