import React from 'react';
import MobileDescriptionRow from '../MobileDescriptionRow';

import './style.css';

const screenApp01 = require('../../../../Assets/Images/App/iphone_screen7.png');

/**
 * ApplicationRow()
 * @returns {*}
 * @constructor
 */
export default function ApplicationRow() {
  return (
    <div className="ApplicationRow FullPageBox Light Center" style={{ marginBottom: 80 }}>
      <div className="container d-flex flex-column">
        <h2 style={{ marginBottom: 10 }}>unsere hey.kitchen Applikation</h2>
        <p>
          Montagezettel vergessen oder verlegt? Überblick über Aufträge verloren? Reklamationsinformationen nicht
          mehr auffindbar? Mit unserer hey.kitchen App wird die Montage digital und für Ihre Mitarbeiter somit
          enorm vereinfacht.
        </p>

        <div className="ListingContainer" style={{ marginTop: 50 }}>
          <div className="Left">
            <MobileDescriptionRow headline="TRANSPARENZ" icon="fas fa-sort-alpha-down">
              Gesamte Auftragshistorie
            </MobileDescriptionRow>
            <MobileDescriptionRow headline="DISPOSITION" icon="fas fa-calendar">
              Intelligente Unterstützung
            </MobileDescriptionRow>
            <MobileDescriptionRow headline="WEBBASIERT" icon="fab fa-internet-explorer">
              Von überall Zugriff, ohne Installation
            </MobileDescriptionRow>
            <MobileDescriptionRow headline="OFFLINE VERFÜGBAR" icon="fas fa-server">
              Montageberichte auch ohne Empfang
            </MobileDescriptionRow>
          </div>
          <div className="Center">
            <img src={screenApp01} alt="HK Prozess Full" style={{ width: '100%' }} />
          </div>
          <div className="Right">
            <MobileDescriptionRow headline="ZEITERFASSUNG" icon="fas fa-people-carry" align="left">
              Montagezeiten automatisch erfasst
            </MobileDescriptionRow>
            <MobileDescriptionRow headline="DOKUMENTENMANAGEMENT" icon="fas fa-file" align="left">
              Alle Dokumente jederzeit abrufbar
            </MobileDescriptionRow>
            <MobileDescriptionRow headline="MONTAGEDAUER" icon="far fa-clock" align="left">
              Berechnung für jede Küche
            </MobileDescriptionRow>
            <MobileDescriptionRow headline="Montagebericht" icon="far fa-envelope" align="left">
              Automatisch an Kunden verschickt
            </MobileDescriptionRow>
          </div>
        </div>
      </div>
    </div>

  );
}
