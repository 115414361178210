import React from 'react';
import InfoBox from '../InfoBox';
import PricingBoxes from '../PricingBoxes';

import './style.css';

/**
 * PricingRow()
 * @returns {*}
 * @constructor
 */
export default function PricingRow() {
  return (
    <div className="PricingRow FullPageBox Light Center">
      <div style={{ marginTop: '-120px' }} id="pricing" />
      <div className="container d-flex flex-column">
        <h2>Preismodell</h2>
        <div className="InfoText">
          <InfoBox headline="Lösung für kleine Unternehmen">
            Sie möchtenhey.kitchen schnell und unkompliziert einführen? Kein Problem! Dafür steht Ihnen
            unser Selbstverwaltungs-Tool zur Verfügung. Einfach Ihr Logo anlegen und Ihre Monteure hinzufügen.
            Die Abrechnung erfolgt ganz bequem pro Nutzer und Monat und kann monatlich gekündigt werden.
          </InfoBox>
          <InfoBox headline="Lösung für große Unternehmen">
            Sie möchten hey.kitchen individuell auf Ihre Firma anpassen? Wir stellen Ihnen ein
            maßgeschneidertes Paket zusammen und führen mit Ihnen gemeinsam hey.kitchen in Ihrem Unternehmen
            ein. Unter Ihrer eigenen Domain, auf ihr Corporate Design angepasst. Kontaktieren Sie uns, für ein
            individuelles Angebot und maximalen Nutzen.
          </InfoBox>
        </div>

        <PricingBoxes />
      </div>
    </div>

  );
}
