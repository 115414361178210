/* eslint-disable react/no-unescaped-entities, max-len */

import React from 'react';
import PropTypes from 'prop-types';
import MaterialTable from './MaterialTable';
import Container from './Container';

import './style.css';

/**
 * AgbDialogDealer()
 * @param props
 * @returns {null|*}
 * @constructor
 */
export default function AgbDialogDealer(props) {
  const { visible, onClose, isPopup } = props;

  if (visible) {
    return (
      <Container onClose={onClose} visible={visible} isPopup={isPopup}>
        <h1>
          Allgemeine  Geschäftsbedingungen  für  die  Beauftragung  der  hey.kitchen  GmbH  durch
          Hauptauftraggeber für Auslieferungs-, Montage- bzw. Installations- und/oder Lagerleistungen von
          Küchen / "Allgemeine Auslieferungs- und Montagebedingungen der hey.kitchen GmbH, Stand November 2019"
        </h1>
        <p>
          Wir, die hey.kitchen GmbH (im Folgenden auch "HK" genannt), betreiben eine Online-Plattform unter
          der Bezeichnung "hey.kitchen" (im Folgenden "Plattform" genannt). Auf der Plattform können – als
          Kunden – registrierte Hauptauftraggeber (insbesondere Möbel-, Einrichtungshäuser, Küchenstudios etc.;
          im Folgenden "Hauptauftraggeber" genannt) Anfragen zur Erbringung von Auslieferungs-, Montage- und
          Lagerleistungen von Küchen, die von Endkunden der Hauptauftraggeber bestellt worden sind, einstellen.
          Diese Anfragen werden von HK als Hauptauftragnehmer – nach Maßgabe dieser Allgemeinen
          Geschäftsbedingungen – bearbeitet und ggf. verbindlich angenommen.
        </p>

        <ol className="Main">
          <li>
            Geltung
            <ol className="Sub">
              <li>
                Alle unsere Lieferungen, Leistungen und Angebote, die wir im Bereich Auslieferungs-, Montage-
                bzw. Installations- und/oder Lagerleistungen von Küchen, Küchenteilen, Küchenwaren für
                Hauptauftraggeber erbringen und/oder abgeben, erfolgen – vorbehaltlich der nachfolgenden
                Ziffer 1.2 – ausschließlich aufgrund dieser Allgemeinen Geschäftsbedingungen für Auslieferungs-,
                Montage- bzw. Installations- und/oder Lagerleistungen von Küchen (im Folgenden kurz: "Allgemeine
                Auslieferungs- und Montagebedingungen" genannt). Diese sind Bestandteil aller Verträge, die wir
                mit den Hauptauftraggebern über die von ihnen angenommenen Lieferungen oder Leistungen im
                Bereich Auslieferungs-, Montage- und Lagerleistungen von Küchen schließen. Sie gelten auch
                für alle zukünftigen Lieferungen, Leistungen oder Angebote durch oder an uns, selbst wenn sie
                nicht nochmals gesondert vereinbart werden.
              </li>
              <li>
                Sofern wir gegenüber dem Hauptauftraggeber ausschließlich speditionsübliche Leistungen,
                insbesondere nur die Beförderung und/oder Lagerung von Waren erbringen, erfolgt dies unter
                Beachtung der jeweils einschlägigen Vorgaben der europäischen Union sowie auf Grundlage der
                jeweils aktuell geltenden Allgemeinen Deutschen Spediteurbedingungen (gegenwärtig:  ADSp 2017)
                und zwingender im europäischen Transport/Speditionsbereich eingreifender Vorschriften
                (insbesondere CMR). Wir weisen ausdrücklich auf die in den ADSp 2017 von den gesetzlichen
                Bestimmungen abweichenden Haftungsregelungen hin, die für den Fall der Geltung der ADSp
                primär gelten. Die ADSp 2017 können unter https://www.hey.kitchen jederzeit abgerufen werden;
                auf Verlangen versenden wir die ADSp 2017. Sofern wir Transportdienstleistungen innerhalb
                von anderen Staaten erbringen gelten – anstelle der ADSp 2017 – die jeweils geltenden nationalen
                Spediteurbedingungen. Ergänzend zu den jeweils geltenden gesetzlichen Grundlagen und den
                jeweils nationalen Spediteurbedingungen gelten diese Allgemeinen Auslieferungs- und
                Montagebedingungen.
              </li>
              <li>
                Geschäftsbedingungen unserer Hauptauftraggeber oder Dritter finden keine Anwendung, auch wenn wir
                ihrer Geltung im Einzelfall nicht gesondert widersprechen. Selbst wenn wir auf ein Schreiben Bezug
                nehmen, das Geschäftsbedingungen des Hauptauftraggebers oder eines Dritten enthält oder auf solche
                verweist, liegt darin kein Einverständnis mit der Geltung jener Geschäftsbedingungen.
              </li>
            </ol>
          </li>

          <li>
            Vertragsschluss
            <ol className="Sub">
              <li>
                Der Hauptauftraggeber gibt auf der Plattform die erforderlichen Daten für einen Auftrag ein.
                Durch die Einstellung eines Angebotes zur Übernahme dieses Auftrages auf der Plattform machen
                wir gegenüber dem Hauptauftraggeber ein verbindliches Angebot.
              </li>
              <li>
                Der Hauptauftraggeber kann ein solches Angebot verbindlich annehmen. Nimmt der Hauptauftraggeber
                ein Angebot an, kommt der Hauptauftraggebervertrag – nach Maßgabe dieser Allgemeinen Auslieferungs-
                und Montagebedingungen – zustande (im Folgenden "Vertrag" und/oder "Einzelauftrag" genannt). Wir
                werden dem Hauptauftraggeber unverzüglich nach Eingang seiner Annahme eine Bestätigung über die
                Annahme und den Vertragsschluss zum jeweiligen Einzelauftrag zusenden.
              </li>
              <li>
                Durch den Abschluss eines Einzelauftrages und/oder mit diesen Allgemeinen Auslieferungs- und
                Montagebedingungen entsteht für keine der Vertragsparteien ein durchsetzbarer Anspruch auf die
                Erteilung von Einzelaufträgen und/oder die Annahme von angebotenen Einzelaufträgen.
              </li>
              <li>
                Der Hauptauftraggeber ist berechtigt, Zeit und Ort der Leistungen sowie Art und Umfang der
                Leistung durch schriftliche Mitteilung vor dem vereinbarten Leistungs-/Liefertermin zu ändern,
                sofern dies zwingend erforderlich und zumutbar ist. Gleiches gilt für sämtliche (Teil-)Änderungen
                in Bezug auf die nach Maßgabe des jeweiligen Einzelauftrages auszuliefernden, zu montierenden
                bzw. anzuschließenden und/oder zu lagernden Gegenstände bzw. Küchen. Wir sind nur verpflichtet,
                einem solchen Änderungsverlangen nachzukommen, soweit dieses für uns zumutbar ist. Haben solche
                Änderungen Leistungs-/Lieferverzögerungen zur Folge, die sich in unserem normalen Produktions-
                und Geschäftsbetrieb nicht mit zumutbaren Anstrengungen vermeiden lassen, verschiebt sich der
                ursprünglich vereinbarte Leistungs-/Liefertermin entsprechend. Wir werden die von uns bei
                sorgfältiger Einschätzung zu erwartenden Mehr- oder Minderkosten oder Leistungs-/
                Lieferverzögerungen rechtzeitig vor dem Leistungs-/Liefertermin, mindestens jedoch innerhalb
                von 3 Kalendertagen nach Zugang der Mitteilung gemäß Satz 1 schriftlich anzeigen.
              </li>
              <li>
                Wir sind berechtigt, jederzeit durch schriftliche Erklärung unter Angabe des Grundes vom
                Vertrag/Einzelauftrag zurückzutreten, wenn wir die beauftragten Leistungen in unserem
                Geschäftsbetrieb aufgrund von nach Vertragsschluss eingetretenen, vom Hauptauftraggeber zu
                vertretenen Umständen (wie z.B. die fehlende Einhaltung von gesetzlichen und/oder vertraglichen
                Anforderungen) nicht mehr oder nur mit erheblichen Aufwendungen erbringen können oder sich die
                Vermögensverhältnisse des Hauptauftraggebers nach Vertragsschluss derart verschlechtern, dass
                mit einer vertragsgemäßen Lieferung/Leistung durch den Hauptauftraggeber nicht zu rechnen ist.
              </li>
              <li>
                Tritt der Hauptauftraggeber – egal aus welchen Rechtsgründen –vor dem Leistungs-/Liefertermin
                (= Auftragsdatum) zurück, so können wir eine angemessene Entschädigung vom Hauptauftraggeber
                verlangen, soweit der Rücktritt nicht von uns aus zu vertreten ist oder am Leistungs-/Lieferort
                oder in dessen Nähe unvermeidbare und/oder außergewöhnliche Umstände auftreten, die die
                Durchführung der von uns zu erbringenden Leistungen erheblich beeinträchtigen. Umstände sind
                unvermeidbar und/oder außergewöhnlich, wenn sie nicht unserer Kontrolle unterliegen und sich
                ihre Folgen auch dann nicht hätten vermeiden lassen, wenn alle zumutbaren Vorkehrungen getroffen
                worden wären.

                <br />
                <br />

                Die Höhe der uns hiernach zustehenden Entschädigung ist in Form von Stornokosten wie folgt
                pauschaliert:

                <ul className="SubListing">
                  <li>
                    Erfolgt der Rücktritt durch den Hauptauftraggeber zeitlich mit einer Frist  von mindestens
                    14 Kalendertagen vor dem Leistungs-/Liefertermin (= Auftragsdatum), so beträgt die vom
                    Hauptauftraggeber an uns zu leistende Entschädigung pauschal 25 % der vereinbarten Vergütung.
                  </li>
                  <li>
                    Erfolgt der Rücktritt durch den Hauptauftraggeber innerhalb von 14 Kalendertagen aber mit
                    einer Frist von 7 mindestens Kalendertagen vor dem Leistungs-/Liefertermin (= Auftragsdatum),
                    so beträgt die vom Hauptauftraggeber an uns zu leistende Entschädigung pauschal 50 % der
                    vereinbarten Vergütung.
                  </li>
                  <li>
                    Erfolgt der Rücktritt durch den Hauptauftraggeber innerhalb von 7 Kalendertagen vor dem
                    Leistungs-/Liefertermin (= Auftragsdatum), so beträgt die vom Hauptauftraggeber an uns zu
                    leistende Entschädigung pauschal 100 % der vereinbarten Vergütung.
                  </li>
                </ul>

                Es bleibt dem Hauptauftraggeber der Nachweis gestattet, die uns zustehende angemessene
                Entschädigung sei wesentlich niedriger als die geforderte Entschädigungspauschale.

                Wir behalten uns vor, anstelle der vorstehenden Entschädigungs-/Stornokostenpauschalen eine
                höhere, individuell berechnete Entschädigung zu fordern, soweit wir nachweisen, dass uns
                wesentlich höhere Aufwendungen als die jeweils anwendbare Stornokostenpauschale entstanden sind.

              </li>
            </ol>
          </li>

          <li>
            Gegenstand unserer Leistungen
            <ol className="Sub">
              <li>
                Wir übernehmen – nach Maßgabe dieser Allgemeinen Auslieferungs- und Montagebedingungen –
                je nach Einzelauftrag für den Hauptauftraggeber folgende Leistungen:

                <ul className="SubListing">
                  <li>
                    Auslieferung, Montage bzw. Installation von Küchen, Küchenteilen, Küchenwaren (inklusive
                    Installation sämtlicher Anschlüsse und Geräte) und/oder
                  </li>
                  <li>
                    Kundendienstleistungen (wie insbesondere die Bearbeitung von Reklamationen) und/oder
                  </li>
                  <li>
                    Einholung von Einziehungsermächtigung von Geldforderungen und/oder
                  </li>
                  <li>
                    Lagerung von Küchen, Küchenteilen, Küchenwaren, Geräten und Installationsprodukten.
                  </li>
                </ul>

              </li>
              <li>
                Wir haben die Leistung, soweit nicht ausdrücklich Abweichendes vereinbart worden ist, nicht
                höchstpersönlich zu erbringen. Einer Übertragung von Leistungen und/oder Teilleistungen auf
                Subunternehmer/Nachunternehmer stimmt der Hauptauftraggeber ausdrücklich zu; eine solche
                Übertragung ist stets zulässig. Wir stehen für unsere Subunternehmer/Nachunternehmer ein
                wie für eigenes Handeln.
              </li>
              <li>
                Wir sind – unter Beachtung der vertraglich vereinbarten Bedingungen – frei in der Erbringung
                unserer Leistungen. Wir sind stets und ausschließlich selbständig im eigenen Namen und für
                eigene Rechnung tätig; wir sind weder berechtigt noch verpflichtet, den Hauptauftraggeber
                rechtsgeschäftlich oder anderweitig zu vertreten.
              </li>
            </ol>
          </li>

          <li>
            Auslieferung, Montage, Installation und/oder Lagerung von Küchen und Elektrogeräten
            <ol className="Sub">
              <li>
                Wir sind gegenüber dem Hauptauftraggeber zur vertragsgemäßen, vollständigen, termingerechten
                sowie sach- und fachgerechten Ausführung unserer Leistungen verpflichtet, und zwar – je nach
                Gegenstand des Vertrag/Einzelauftrages – insbesondere

                <ul className="SubListing">
                  <li>
                    zur termin-, sach- und fachgerechten Auslieferung und Montage von Küchen bzw. Küchenteilen
                    an/bei den Endkunden des Hauptauftraggebers und/oder
                  </li>
                  <li>
                    zum termin-, sach- und fachgerechten sowie funktionsfähigen Anschluss der Küche bzw.
                    Küchenteilen, hierbei insbesondere zum sach- und fachgerechten sowie funktionsfähigen
                    Anschluss von Einbau- und/oder Elektrogeräten, Wasser- und/oder Abwasseranschlüssen bei
                    den Endkunden des Hauptauftraggebers und/oder
                  </li>
                  <li>
                    zur sach- und fachgerechten Lagerung von Küchen bzw. Küchenteilen für den Endkunden des
                    Hauptauftraggebers.
                  </li>
                </ul>
              </li>
              <li>
                Wir erbringen unsere Leistungen durch einwandfreies Material (insbesondere Fahrzeuge, Werkzeug)
                sowie geschultes, zuverlässiges Fachpersonal nach den anerkannten Regeln der Technik und des
                Handwerks.
              </li>
              <li>
                Wir übernehmen die – nach Maßgabe des jeweiligen Einzelauftrages – auszuliefernde bzw. zu
                montierende/zu installierende Ware an dem vorgegebenen Zeitpunkt an dem angegebenen Abholort.
                Mit der zur Übernahme für uns bereitgestellten Ware werden uns von dem Hauptauftraggeber die
                entsprechenden Lieferpapiere übergeben. Wir sind berechtigt und verpflichtet, vor Übernahme
                der Ware diese auf sichtbare Unversehrtheit und sichtbare Vollständigkeit zu prüfen; ist die
                Ware sichtbar unversehrt und sichtbar vollständig, ist dies auf dem Bereitstellungsschein/
                Lieferschein entsprechend zu bestätigen. Ist die Ware sichtbar versehrt oder sichtbar
                unvollständig, so ist dies auf dem Bereitstellungsschein/Lieferschein entsprechend zu
                vermerken; wir sind hierüber unverzüglich zu informieren. Gefahr und Haftung für die Ware
                gehen nur nach der Bestätigung der Unversehrtheit und Vollständigkeit durch uns vollständig
                auf uns über; wir haften ab diesem Zeitpunkt für Verlust an der Ware und Schäden, sofern wir
                diese grob fahrlässig oder vorsätzlich verursacht hat.
              </li>
              <li>
                Wir werden das Abnahmeprotokoll über die von uns erbrachten Leistungen (insbesondere Auslieferung
                und Montage der Küche und der Geräte) vollständig ausfüllen und dieses von dem Endkunden des
                Hauptauftraggebers und uns (bzw. einem beteiligten Monteur) unterzeichnen lassen. Hinsichtlich
                der noch offenen und auf dem Lieferschein/Rechnungsformular des Hauptauftraggebers ausgewiesen
                Restbeträge werden wir von dem Endkunden – nach Maßgabe von nachstehender Ziffer 5. dieser
                Allgemeinen Auslieferungs- und Montagebedingungen – die von uns zur Verfügung gestellte
                Einzugsermächtigung ausfüllen und unterzeichnen lassen.
              </li>
            </ol>
          </li>

          <li>
            Einholung einer Einziehungsermächtigung
            <ol className="Sub">
              <li>
                Ein etwaiger (Rest-)Kaufpreis für die von uns für den Hauptauftraggeber ausgelieferten bzw.
                montierten Küchen wird von dem jeweiligen Endkunden mittels zur Vorlage beim Endkunden zur
                Verfügung gestellten Einzugsermächtigung des Hauptauftraggebers eingezogen. Voraussetzung
                hierfür ist, dass der Hauptauftraggeber die hierfür erforderlichen Daten (Gläubiger ID,
                Bankverbindungen, Logo, etc.) in seinen Stammdaten auf unserer Plattform vollständig hinterlegt
                hat. Wir werden wird dem Endkunden die so zur Verfügung gestellte Einzugsermächtigung am
                Erstmontagetermin vor Beginn der Auslieferungs- und/oder Montage-/Installationsleistungen
                vorlegen und von diesem ausfüllen und unterzeichnen lassen.
              </li>
              <li>
                Wir erhalten von dem Hauptauftraggeber ausdrücklich keine Inkassovollmacht. Wir sind nicht
                berechtigt und wir sind ausdrücklich nicht verpflichtet, etwaige Zahlungsmittel vom Endkunden
                – auch nicht treuhänderisch – in Empfang zu nehmen.
              </li>
            </ol>
          </li>

          <li>
            Kundendienst/Bearbeitung von Reklamationen
            <ol className="Sub">
              <li>
                Wir sind zur sach- und fachgerechten Erbringung von Kundendienstleistungen an Küchen, wie
                insbesondere auch zur Bearbeitung und Behebung von Reklamationen, beim Endkunden des
                Hauptauftraggebers verpflichtet.
              </li>
              <li>
                Im Falle von Reklamationen werden wir diese– so zügig wie dies möglich ist – beim Endkunden
                nach Maßgabe der nachfolgenden Bestimmungen bearbeiten:

                <ul className="SubListing">
                  <li>
                    Die erste Stunde Bearbeitung und Behebung der ersten Reklamation des Endkunden durch uns ist mit
                    der in dem schriftlich bestätigten Vertrag/Einzelauftrag ausgewiesenen Vergütung gemäß
                    nachstehender Ziffer 9.1 abgedeckt.; Sämtliche darüber hinausgehenden Reklamationsleistungen
                    durch uns werden – gegen entsprechenden Nachweis durch uns – mit - pauschal € 41,00 netto pro
                    Arbeitsstunde und pro Monteur vergütet. Sofern die Reklamation des Endkunden nicht auf
                    einer nicht ordnungsgemäßen Leistungserbringung durch uns beruht, wie insbesondere auf fehlerhaften
                    und/oder nicht vollständigen Liefergegenständen, ist der Hauptauftraggeber verpflichtet, uns die
                    ordnungsgemäßen und/oder vollständigen Liefergegenstände unverzüglich zur Verfügung zu
                    stellen, damit wir die Reklamation bearbeiten können; sofern der Hauptauftraggeber seinen
                    Pflichten hiernach nicht nachkommt und/oder nachkommen kann, ruht unsere Pflicht zur
                    Bearbeitung von Reklamationen.
                  </li>
                  <li>
                    Die Termine zur Bearbeitung und Behebung von Reklamationen durch uns werden vom
                    Hauptauftraggeber – nach seiner Abstimmung mit dem Endkunden – durch Mitteilung
                    einer in Betracht kommenden Kalenderwoche vorgegeben. Wir werden gegenüber dem
                    Hauptauftraggeber drei Terminvorschläge (mit entsprechendem Datum und Uhrzeit) für
                    die vom Hauptauftraggeber vorgegebene Kalenderwoche nennen; der Hauptauftraggeber ist
                    verpflichtet, einen dieser von uns genannten Termine zu bestätigen, sofern dies tatsächlich
                    möglich ist. Andernfalls muss vom Hauptauftraggeber solange eine andere Kalenderwoche
                    vorgegeben werden, bis einer der diesbezüglich von uns genannten Termine tatsächlich
                    bestätigt werden kann.
                  </li>
                </ul>
              </li>
              <li>
                Die Vorschriften zur Gewährleistung gemäß nachstehender Ziffer 12. bleiben hiervon unberührt.
              </li>
            </ol>
          </li>

          <li>
            Sonstige Pflichten des Hauptauftraggebers
            <ol className="Sub">
              <li>
                Wir werden das Verpackungsmaterial der an den Endkunden ausgelieferten Ware sowie den im Rahmen
                der vertraglichen Arbeiten anfallenden Abfall sach- und fachgerecht entsorgen.
              </li>
              <li>
                Für zusätzliche Materialien, die wir/ für die Erbringung unserer, insbesondere für den Elektro-
                und/oder Sanitäranschluss, benötigen/benötigt, gilt unsere jeweils gültige Preisliste.

                <div style={{ padding: 30 }}>
                  <MaterialTable type="DEALER" />
                </div>
              </li>
              <li>
                Sofern gesetzlich vorgeschrieben (insbesondere aufgrund des ElektroG), werden wir für den
                Hauptauftraggeber Altgeräte vom Endkunden zurückzunehmen und an den Hauptauftraggeber zu übergeben.
              </li>
            </ol>
          </li>

          <li>
            Versicherungen
            <ol className="Sub">
              <li>
                Wir werden die üblichen Risiken unserer Tätigkeiten, insbesondere die mit der Lagerung, der
                Auslieferung, der Installation und Montage der Ware verbundenen Risiken mit den hierfür
                üblichen und angemessenen Deckungssummen auf eigene Kosten zu versichern (z.B. Lagerversicherung,
                Transportversicherung, Haftpflichtversicherung etc.); diese Versicherungen werden wir während
                der Dauer des Vertrages und der Gewährleistungsfrist bzw. Garantiefrist unterhalten und dies
                gegenüber dem Hauptauftraggeber auf Verlangen schriftlich nachweisen.
              </li>
            </ol>
          </li>

          <li>
            Vergütung, Zahlungsbedingungen, Rechnungsangaben
            <ol className="Sub">
              <li>
                Die in dem von uns gegenüber dem Hauptauftraggeber schriftlich bestätigten Vertrag/Einzelauftrag
                ausgewiesene Vergütung ist bindend. Die Vertragspreise sind Festpreise.
              </li>
              <li>
                Sofern nicht etwas anderes vereinbart ist, ist der Hauptauftraggeber verpflichtet, ab
                ordnungsgemäßer, vollständiger und termingerechter Erbringung der Leistung (z.B. nach
                ordnungsgemäßer, vollständiger und termingerechter Erstmontage der Küche) und Rechnungserhalt
                die vereinbarte Vergütung innerhalb von 7 Kalendertagen ohne jeden Abzug zu zahlen. Für die
                Rechtzeitigkeit der uns gegenüber geschuldeten Zahlungen ist der Zahlungseingang bei unserer
                Bank maßgeblich.
              </li>
              <li>
                In sämtlichen Auftragsbestätigungen, Lieferpapieren und Rechnungen sind unsere Auftragsnummer,
                die Artikel-Nr., Leistungs-/Liefermenge und Endkundenanschrift anzugeben.
              </li>
              <li>
                Leistet der Hauptauftraggeber bei Fälligkeit nicht, so sind die ausstehenden Beträge ab dem Tag
                der Fälligkeit mit 5 Prozentpunkten über dem Basiszinssatz p.a. zu verzinsen; die Geltendmachung
                höherer Zinsen und weiterer Schäden im Falle des Verzugs bleibt unberührt.
              </li>
              <li>
                Die Aufrechnung mit Gegenansprüchen des Hauptauftraggebers oder die Zurückbehaltung von Zahlungen
                wegen solcher Ansprüche ist nur zulässig, soweit die Gegenansprüche unbestritten oder
                rechtskräftig festgestellt sind.
              </li>
              <li>
                Wir sind berechtigt, noch ausstehende Leistungen oder Lieferungen nur gegen Vorauszahlung oder
                Sicherheitsleistung auszuführen oder zu erbringen, wenn uns nach Abschluss des Vertrages Umstände
                bekannt werden, welche die Kreditwürdigkeit des Hauptauftraggebers wesentlich zu mindern geeignet
                sind und durch welche die Bezahlung unserer offenen Forderungen durch den Hauptauftraggeber aus
                dem jeweiligen Vertragsverhältnis (einschließlich aus anderen Einzelaufträgen, für die derselbe
                Rahmenvertrag gilt) gefährdet wird.
              </li>
            </ol>
          </li>

          <li>
            Lieferzeit und Lieferung
            <ol className="Sub">
              <li>
                Der Hauptauftraggeber ist verpflichtet, uns unverzüglich schriftlich zu informieren, wenn Umstände
                eintreten oder erkennbar werden, wonach die Leistungs-/Lieferzeit nicht eingehalten werden kann.
              </li>
              <li>
                Wir können – unbeschadet unserer Rechte aus Verzug des Hauptauftraggebers – vom Hauptauftraggeber
                eine Verlängerung von Leistungs- und Lieferfristen oder eine Verschiebung von Leistungs- und
                Lieferterminen um den Zeitraum verlangen, in dem der Hauptauftraggeber uns gegenüber seinen
                vertraglichen Verpflichtungen nicht nachkommt.
              </li>
              <li>
                Wir haften nicht für Unmöglichkeit der Leistung/Lieferung oder für Leistungs-/Lieferverzögerungen,
                soweit diese durch höhere Gewalt oder sonstige, zum Zeitpunkt des Vertragsabschlusses nicht
                vorhersehbare Ereignisse (z.B. Betriebsstörungen aller Art, Schwierigkeiten in der Material-
                oder Energiebeschaffung, Transportverzögerungen, Streiks, rechtmäßige Aussperrungen, Mangel an
                Arbeitskräften, Energie oder Rohstoffen, Schwierigkeiten bei der Beschaffung von notwendigen
                behördlichen Genehmigungen, behördliche Maßnahmen oder die ausbleibende, nicht richtige oder
                nicht rechtzeitige Belieferung durch den Hauptauftraggeber oder Lieferanten) verursacht worden
                sind, die wir nicht zu vertreten haben. Sofern solche Ereignisse uns die Lieferung oder Leistung
                wesentlich erschweren oder unmöglich machen und die Behinderung nicht nur von vorübergehender
                Dauer ist, sind wir zum Rücktritt vom Vertrag berechtigt. Bei Hindernissen vorübergehender Dauer
                verlängern sich die Liefer- oder Leistungsfristen oder verschieben sich die Liefer- oder
                Leistungstermine um den Zeitraum der Behinderung zzgl. einer angemessenen Anlauffrist. Soweit
                dem Hauptauftraggeber bzw. dem Endkunden infolge der Verzögerung die Abnahme der Lieferung oder
                Leistung nicht zuzumuten ist, kann der Hauptauftraggeber durch unverzügliche schriftliche
                Erklärung uns gegenüber vom Vertrag zurücktreten.
              </li>
              <li>
                Wir sind zu Teilleistungen/-lieferungen berechtigt, wenn

                <ul className="SubListing">
                  <li>
                    die Teilleistung/-lieferung für den Hauptauftraggeber bzw. den Endkunden im Rahmen des
                    vertraglichen Bestimmungszwecks verwendbar ist,
                  </li>
                  <li>
                    die Lieferung der restlichen bestellten Ware sichergestellt ist und/oder
                  </li>
                  <li>
                    dem Auftraggeber hierdurch kein erheblicher Mehraufwand oder zusätzliche Kosten
                    entstehen (es sei denn, der Verkäufer erklärt sich zur Übernahme dieser Kosten bereit).
                  </li>
                </ul>
              </li>
              <li>
                Geraten wir mit einer Lieferung oder Leistung in Verzug oder wird uns eine Lieferung oder Leistung,
                gleich aus welchem Grunde, unmöglich, so ist unsere auf Schadensersatz nach Maßgabe von Ziffer 13.
                dieser Allgemeinen Auslieferungs- und Montagebedingungen beschränkt.
              </li>
              <li>
                Erfüllungsort für alle Verpflichtungen aus dem Vertragsverhältnis ist der vom Endkunden angegebene
                Ort, soweit nichts anderes bestimmt ist. Schuldet wir auch die Installation, ist Erfüllungsort
                der Ort, an dem die Installation zu erfolgen hat.
              </li>
              <li>
                Der Transport untersteht unserem pflichtgemäßen Ermessen.
              </li>
              <li>
                Die Gefahr und Haftung für die Ware gehen mit Unterzeichnung des Abnahmeprotokolls durch den
                Endkunden des Hauptauftraggebers vollständig auf den Endkunden über, sofern dies im Verhältnis
                zwischen dem Endkunden einerseits und dem Hauptauftraggeber andererseits entsprechend gilt;
                anderenfalls gehen die Gefahr und Haftung mit diesem Zeitpunkt auf den Hauptauftraggeber über.
              </li>
            </ol>
          </li>

          <li>
            Eigentumssicherung
            <ol className="Sub">
              <li>
                An von uns abgegebenen Bestellungen, Aufträgen sowie dem Hauptauftraggeber/Endkunden zur Verfügung
                gestellten Zeichnungen, Abbildungen, Berechnungen, Beschreibungen und anderen Unterlagen behalten
                wir uns – soweit diese nicht ohnehin Dritten zustehen – das Eigentum oder Urheberrecht vor.
                Der Hauptauftraggeber/Endkunden darf sie ohne unsere ausdrückliche Zustimmung weder Dritten
                zugänglich machen noch selbst oder durch Dritte nutzen oder vervielfältigen. Er hat diese
                Unterlagen auf unser Verlangen vollständig an uns zurückzugeben, wenn sie von ihm im
                ordnungsgemäßen Geschäftsgang nicht mehr benötigt werden oder wenn Verhandlungen nicht
                zum Abschluss eines Vertrages führen. Vom Hauptauftraggeber/Endkunden hiervon angefertigte
                Kopien sind in diesem Fall zu vernichten; ausgenommen hiervon sind nur die Aufbewahrung im
                Rahmen gesetzlicher Aufbewahrungspflichten sowie die Speicherung von Daten zu Sicherungszwecken
                im Rahmen der üblichen Datensicherung.
              </li>
              <li>
                Eigentumsvorbehalte des Hauptauftraggebers/Endkunden gelten nur, soweit sie sich auf unsere
                Zahlungsverpflichtung für solche Produkte beziehen, an denen der Hauptauftraggeber/Endkunden sich
                das Eigentum vorbehält. Insbes. sind erweiterte oder verlängerte Eigentumsvorbehalte unzulässig.
              </li>
            </ol>
          </li>

          <li>
            Gewährleistungsansprüche
            <ol className="Sub">
              <li>
                Die Gewährleistungsfrist beträgt ein Jahr ab Lieferung/Leistung oder, soweit eine Abnahme
                erforderlich ist, ab der Abnahme. Diese Frist gilt nicht für Schadensersatzansprüche des
                Hauptauftraggebers aus der Verletzung des Lebens, des Körpers oder der Gesundheit oder aus
                vorsätzlichen oder grob fahrlässigen Pflichtverletzungen durch uns oder unsere Erfüllungsgehilfen,
                welche jeweils nach den gesetzlichen Vorschriften verjähren.
              </li>
              <li>
                Die gelieferten/montierten/installierten Gegenstände sind unverzüglich nach Ablieferung an den
                Endkunden des Hauptauftraggebers oder an den von diesen bestimmten Dritten sorgfältig zu
                untersuchen. Sie gelten hinsichtlich offensichtlicher Mängel oder anderer Mängel, die bei
                einer unverzüglichen, sorgfältigen Untersuchung erkennbar gewesen wären, als vom Hauptauftraggeber
                /Endkunden genehmigt, wenn uns nicht binnen 7 Kalendertagen nach Ablieferung eine schriftliche
                Mängelrüge zugeht. Hinsichtlich anderer Mängel gelten die Liefergegenstände als vom
                Hauptauftraggeber/Endkunden genehmigt, wenn uns die Mängelrüge nicht binnen 7 Kalendertagen
                nach dem Zeitpunkt zugeht, in dem sich der Mangel zeigte; war der Mangel bei normaler Verwendung
                bereits zu einem früheren Zeitpunkt offensichtlich, ist jedoch dieser frühere Zeitpunkt für den
                Beginn der Rügefrist maßgeblich.
              </li>
              <li>
                Bei Mängeln unserer Leistungen stehen dem Hauptauftraggeber die gesetzlichen Ansprüche zu.
              </li>
              <li>
                Beruht ein Mangel auf unserem Verschulden, kann der Hauptauftraggeber nur unter den in
                nachstehender Ziffer 13. bestimmten Voraussetzungen Schadensersatz verlangen.
              </li>
              <li>
                Bei Mängeln von Bauteilen, die wir – nach Maßgabe der einzelnen vertraglichen Bestimmungen –an
                den Endkunden ausliefern, montieren, installieren und/oder lagern, und die wir insbesondere aus
                lizenzrechtlichen oder tatsächlichen Gründen nicht beseitigen können, sind wir nicht verpflichtet.
              </li>
              <li>
                Die Gewährleistung entfällt, wenn und soweitder Hauptauftraggeber seinen vertraglichen Pflichten
                nicht nachkommt und die Mängelbeseitigung hierdurch unmöglich oder unzumutbar erschwert wird. In
                jedem Fall hat der Hauptauftraggeber die durch sein Verschulden entstehenden Mehrkosten der
                Mängelbeseitigung zu tragen.
              </li>
            </ol>
          </li>

          <li>
            Haftung
            <ol className="Sub">
              <li>
                Unsere Haftung auf Schadensersatz, gleich aus welchem Rechtsgrund, insbes. aus Unmöglichkeit,
                Verzug, mangelhafter oder falscher Lieferung, Vertragsverletzung, Verletzung von Pflichten bei
                Vertragsverhandlungen und unerlaubter Handlung ist, soweit es dabei jeweils auf ein Verschulden
                ankommt, nach Maßgabe dieser Ziffer 13. eingeschränkt.
              </li>
              <li>
                Wir haften nicht im Falle einfacher Fahrlässigkeit unserer Organe, gesetzlichen Vertreter,
                Angestellten oder sonstigen Erfüllungsgehilfen (insbesondere unserer Nachunternehmer), soweit
                es sich nicht um eine Verletzung vertragswesentlicher Pflichten handelt. Vertragswesentlich
                sind die Verpflichtung zur rechtzeitigen Lieferung und Installation des Liefergegenstands,
                dessen Freiheit von Rechtsmängeln sowie solchen Sachmängeln, die seine Funktionsfähigkeit oder
                Gebrauchstauglichkeit mehr als nur unerheblich beeinträchtigen, sowie Beratungs-, Schutz- und
                Obhutspflichten, die dem Endkunden des Hauptauftraggebers die vertragsgemäße Verwendung des
                Liefergegenstands ermöglichen sollen oder den Schutz von Leib oder Leben oder den Schutz von
                dessen Eigentum vor erheblichen Schäden bezwecken.
              </li>
              <li>
                Soweit wir gemäß dem Grunde nach auf Schadensersatz haften, ist diese Haftung auf Schäden
                begrenzt, die wir bei Vertragsschluss als mögliche Folge einer Vertragsverletzung vorausgesehen
                haben oder die wir bei Anwendung verkehrsüblicher Sorgfalt hätten voraussehen müssen. Mittelbare
                Schäden und Folgeschäden, die Folge von Mängeln des Liefergegenstands sind, sind nicht
                ersatzfähig und ausdrücklich ausgeschlossen.
              </li>
              <li>
                Die vorstehenden Haftungsausschlüsse und -beschränkungen gelten in gleichem Umfang zugunsten
                unserer Organe, gesetzlichen Vertreter, Angestellten und sonstigen Erfüllungsgehilfen
                (insbesondere in Bezug auf Nachunternehmer).
              </li>
              <li>
                Soweit wir technische Auskünfte geben oder beratend tätig werden und diese Auskünfte oder
                Beratung nicht zu dem von uns geschuldeten, vertraglich vereinbarten Leistungsumfang gehören,
                geschieht dies unentgeltlich und unter Ausschluss jeglicher Haftung.
              </li>
              <li>
                Die Einschränkungen dieser Ziffer 13. gelten nicht für unsere Haftung wegen vorsätzlichen
                Verhaltens, für garantierte Beschaffenheitsmerkmale, wegen Verletzung des Lebens, des
                Körpers oder der Gesundheit oder nach dem Produkthaftungsgesetz.
              </li>
            </ol>
          </li>

          <li>
            Schutzrechte
            <ol className="Sub">
              <li>
                Der Hauptauftraggeber steht nach Maßgabe der nachstehenden Ziffer 14.2 dafür ein, dass durch
                ein – etwaig – von ihm geliefertes (fehlerhaftes) Produkt und/oder die von ihm erbrachten
                Leistungen keine Schutzrechte Dritter in Ländern der Europäischen Union oder anderen Ländern
                verletzt werden.
              </li>
              <li>
                Der Hauptauftraggeber ist verpflichtet, uns von allen Ansprüchen freizustellen, die Dritte
                gegen uns wegen der in Ziffer 14.1 genannten Verletzung von gewerblichen Schutzrechten erheben,
                und uns alle notwendigen Aufwendungen im Zusammenhang mit dieser Inanspruchnahme zu erstatten.
                Dies gilt nicht, soweit der Hauptauftraggeber nachweist, dass er die Schutzrechtsverletzung
                weder zu vertreten hat noch bei Anwendung kaufmännischer Sorgfalt zum Zeitpunkt der Lieferung
                hätte kennen müssen.
              </li>
              <li>
                Unsere weitergehenden gesetzlichen Ansprüche wegen Rechtsmängeln der uns gegenüber erbrachten
                Leistungen bleiben unberührt.
              </li>
            </ol>
          </li>

          <li>
            Geheimhaltung
            <ol className="Sub">
              <li>
                Der Hauptauftraggeber ist verpflichtet, die Bedingungen des jeweiligen Einzelauftrages sowie
                sämtliche ihm für diesen Zweck zur Verfügung gestellten Informationen und Unterlagen (mit
                Ausnahme von öffentlich zugänglichen Informationen) für einen Zeitraum von 5 Jahren nach
                Vertragsschluss geheim zu halten und nur zur Ausführung des Einzelauftrages zu verwenden. Er
                wird sie nach Erledigung von Anfragen oder nach Abwicklung des Einzelauftrages auf Verlangen
                umgehend an uns zurückgeben.
              </li>
              <li>
                Ohne unsere vorherige schriftliche Zustimmung darf der Hauptauftraggeber in Werbematerial,
                Broschüren etc. nicht auf die Geschäftsverbindung hinweisen und für uns gefertigte
                Liefergegenstände nicht ausstellen.
              </li>
              <li>
                Der Nachunternehmer wird etwaige dritte Subunternehmer – sofern solche nach Maßgabe dieser
                Allgemeinen Auslieferungs- und Montagebedingungen eingesetzt werden dürfen – entsprechend
                dieser Ziffer 15. verpflichten.
              </li>
            </ol>
          </li>

          <li>
            Abtretung
            <ol className="Sub">
              <li>
                Der Hauptauftraggeber ist nicht berechtigt, seine Forderungen aus dem Vertragsverhältnis an
                Dritte abzutreten. Dies gilt nicht, soweit es sich um Geldforderungen handelt.
              </li>
            </ol>
          </li>

          <li>
            Einhaltung von Gesetzen
            <ol className="Sub">
              <li>
                Der Hauptauftraggeber ist verpflichtet, im Zusammenhang mit dem Vertragsverhältnis die jeweils für
                ihn maßgeblichen gesetzlichen Bestimmungen einzuhalten. Dies betrifft insbesondere Antikorruptions-
                und Geldwäschegesetze sowie kartellrechtliche, arbeits- und umweltschutzrechtliche Vorschriften.
                Auf unsere Verlangen hat der Hauptauftraggeber uns gegenüber entsprechende schriftliche Nachweise
                hierüber vorzulegen. Der Hauptauftraggeber haftet vollumfänglich für etwaige uns oder Dritten
                durch die Nichteinhaltung von für ihn maßgeblichen gesetzlichen Bestimmungen entstehende Schäden
                und hat uns diesbezüglich vollumfänglich freizustellen.
              </li>
              <li>
                Der Hauptauftraggeber wird sicherstellen, dass die von ihm erbrachten Leistungen allen
                maßgeblichen Anforderungen an das Inverkehrbringen in der Europäischen Union und im
                Europäischen Wirtschaftsraum genügen. Er hat uns die Konformität auf Verlangen durch
                Vorlage geeigneter Dokumente nachzuweisen.
              </li>
              <li>
                Der Hauptauftraggeber wird zumutbare Anstrengungen unternehmen, um die Einhaltung der in dieser
                Ziffer 17. enthaltenen, den Hauptauftraggeber treffenden Verpflichtungen durch seine etwaigen
                dritten Subunternehmer – sofern solche nach Maßgabe dieser Allgemeinen Auslieferungs- und
                Montagebedingungen eingesetzt werden dürfen – sicherzustellen.
              </li>
            </ol>
          </li>

          <li>
            Gerichtsstand, anwendbares Recht
            <ol className="Sub">
              <li>
                Ausschließlicher Gerichtsstand für alle Streitigkeiten aus dem Vertragsverhältnis ist Köln.
              </li>
              <li>
                Die zwischen uns und dem Hauptauftraggeber geschlossenen Verträge unterliegen dem Recht der
                Bundesrepublik Deutschland unter Ausschluss des Übereinkommens über den internationalen Warenkauf
                (UN-Kaufrechtsübereinkommen/CISG).
              </li>
            </ol>
          </li>

        </ol>
      </Container>
    );
  }
  return null;
}

AgbDialogDealer.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  isPopup: PropTypes.bool,
};

AgbDialogDealer.defaultProps = {
  visible: false,
  isPopup: true,
  onClose: () => {},
};
